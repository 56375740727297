<template>

  <div class="fondo_descargas">
    <umaTop/>
    <h1 class="headerTienda">{{ leBim.nombre }}</h1>
    <div class="product">
          <div class="back-icon-flex">
            <router-link to="/">
              <div class="backIconDiv">
                <i class="arrow lefto"></i>
              </div>
            </router-link>

          </div>
          <div class="product-image-flex">
              <img class="imagen" :src="foto"/>
          </div>

          <div class="product-downloads-flex">
            <div class="product-list">
                <ul class='list'>
                  <p class="titulo">DESCARGAS</p>

                  <li class="flexit">
                      <div class="left">
                        <p>Revit</p>
                      </div>
                      <a :href="revitUrl" @click.prevent="downloadRevit()">
                        <img class="downloadIcon" src="@/assets/downloadIcon.png"/>
                      </a>
                  </li>

                  <li class="flexit">
                      <div class="left">
                        <p v-if="leBim.presupuesto">Presupuestos de {{ leBim.categoria }} en formato FIEBDC</p>
                        <p v-else class="negative">Archivo de presupuesto no disponible</p>
                      </div>
                      <a v-if="leBim.presupuesto" @click.prevent="downloadPresu()">
                        <img class="downloadIcon" src="@/assets/downloadIcon.png"/>
                      </a>
                      <a v-else>
                        <img class="noDownloadIcon" src="@/assets/noDownloadIcon.png"/>
                      </a>
                  </li>

                  <li class="flexit">
                      <div class="left">
                        <p v-if="leBim.pliego">Pliego de condiciones</p>
                        <p v-else class="negative">Pliego de condiciones no disponible</p>
                      </div>
                      <a v-if="leBim.pliego" @click.prevent="downloadPliego()">
                        <img class="downloadIcon" src="@/assets/downloadIcon.png"/>
                      </a>
                      <a v-else>
                        <img class="noDownloadIcon" src="@/assets/noDownloadIcon.png"/>
                      </a>
                  </li>

                  <div style="text-align: center;">
                      <p id="zip">
                      </p>
                  </div>
                </ul>
             </div>
            </div>

          </div>
          <router-view></router-view>

          <div>
            <h2 class="tituloso">Características</h2>
            <div class="tableta">
              <table>
                <tr>
                  <td class="a">Nombre</td>
                  <td>{{leBim.nombre}}</td>
                </tr>
                <tr>
                  <td class="a">Descripción</td>
                  <td>{{leBim.detalles}}</td>
                </tr>
                <tr>
                  <td class="a">Codigo</td>
                  <td>{{leBim.codigo}}</td>
                </tr>

                <tr>
                  <td class="a">Categoría</td>
                  <td>{{categoria}}</td>
                </tr>
              </table>
            </div>
          </div>

          <piePagina />
</div>
</template>

<script>
import umaTop from '@/components/umaTop.vue';
import bims from '@/components/listaBim.js';
import piePagina from "@/components/piePagina.vue";
import axios from "axios";


export default {
  name: "product",
  components: {
    umaTop,
    piePagina
  },
  data() {
    return {
      revitUrl: 'revit/'+ this.code + '.rfa',
      presuUrl: 'presupuestos/'+ this.code + '.pzh',
      pliegoUrl: 'pliegos/'+ this.code + '.pdf',
    }
  },

  methods: {
    downloadRevit() {
      axios({
            url: this.revitUrl,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
             var fileURL = window.URL.createObjectURL(new Blob([response.data]));
             var fileLink = document.createElement('a');

             fileLink.href = fileURL;
             fileLink.setAttribute('download', this.code + '.rfa');
             document.body.appendChild(fileLink);

             fileLink.click();
      });
    },

    downloadPresu() {
      var laUrl = 'presupuestos/'+ this.categoria + '.pzh';
      axios({
            url: laUrl,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
             var fileURL = window.URL.createObjectURL(new Blob([response.data]));
             var fileLink = document.createElement('a');

             fileLink.href = fileURL;
             fileLink.setAttribute('download', this.categoria + '.pzh');
             document.body.appendChild(fileLink);

             fileLink.click();
      });
    },

    downloadPliego() {
      axios({
            url: this.pliegoUrl,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
             var fileURL = window.URL.createObjectURL(new Blob([response.data]));
             var fileLink = document.createElement('a');

             fileLink.href = fileURL;
             fileLink.setAttribute('download', this.code + '.pdf');
             document.body.appendChild(fileLink);

             fileLink.click();
      });
    },



  },

  computed: {

    categoria() {
      var bim = '';
      for (var j=0; j < bims.length ; ++j) {
        if (bims[j].codigo === this.code){
          bim = bims[j].categoria
        }
      }
      return bim;
    },

      foto() {
        return require('@/assets/descargas/revit/' + this.code + '.jpg')
      },

      leBim()  {
        return bims.find( leBim => leBim.codigo === this.code)
      },
  },

  props: {
    code: String
  }
}
</script>

<style scoped>

.negative  {
  color: gray;
}

.backIconDiv  {
  height: 120px;
  border-radius: 60px;
  width: 120px;
  cursor: pointer;
  line-height: 150px;
  margin-right: 40px;
  background-color: white;
  transition-duration: 500ms;
}

.backIconDiv:hover  {
  background-color: lightgray;

}

.arrow {
  border: solid black;
  border-width: 0 10px 10px 0;
  display: inline-block;
  padding: 17px;
  text-align: center;
  margin-left: 10px

}


.lefto {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}


.fondo_descargas {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 55%, rgba(156,192,241,1) 100%);
}

.headerTienda {
  margin: 40px;
  margin-top: 0;
  padding-top: 40px;
  font-family: 'Open Sans', sans-serif, Arial;
  font-weight: 700;
  color: #2c3e50;
}


  .flexit  {
    display: flex;
    align-items: center;
    justify-content: center;

  }


  .downloadIcon  {
    cursor: pointer;
    height: 50px;
    width: auto;
    vertical-align: middle;
  }

  .noDownloadIcon  {
    height: 35px;
    width: 35px;
    vertical-align: middle;
    margin-right: 7px;
    margin-left: 10px;
  }

  .left  {
    float: left;
    align-self: flex-start;
    width: 80%;
    font-size: 1.2em;
  }

  .left  p  {
    text-align: left;
    margin-left: 15px;
  }

  .product {
   display: flex;
   align-items: center;
   justify-content: center;
   padding-bottom: 60px;
   margin-top: 50px;
   flex-wrap: nowrap;
   border-bottom: 1px solid darkgray;
   margin: auto;
   margin-bottom: 40px;
   width: 80%;
  }

  .imagen {
    width: 70%;
    border: 1px solid darkgray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display:block;
    margin:auto;
    max-width: 450px;
    min-width: 300px;
  }

  @media (max-width: 850px) {
    .product {
      flex-wrap: wrap;
      margin-bottom: 20px;
      padding-bottom: 40px;
    }
    .product-downloads {
      margin-top: 30px;
    }
    .header {
      margin: 20px;
      margin-top: 0;
      padding-top: 30px;
      font-size: 1.5em;
      margin-right: 20px;
      margin-left: 30px;
      margin-bottom: 35px;
    }

    .product  {
      width: 100%;
    }

    .imagen{
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }


  @media (max-width: 350px) {
    .imagen {
      min-width: 180px;
    }
  }

  .product-image-flex {
    width: 100%;
  }

  .product-downloads-flex  {
    width: 100%;

  }

  .product-list {
    margin: auto;
    width: 70%;
    max-width: 750px;
  }

  .color-box {
   width: 40px;
   height: 40px;
  }


  .list {
    border: 1px solid darkgray;
    border-radius: 25px;
    list-style-type: none;
    list-style-position: outside;
    margin: 0;
    padding: 0;
    background-color: #003466;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }


  .list li {

     border: 1px solid #d8d8d8;
     background-color: white;
     font-family: 'Open Sans', sans-serif, Arial;
  }



  .titulo  {
    margin-top: 23px;
    margin-bottom: 23px;
    font-weight: 600;
    color: white;
    text-align: center;
    font-family: 'Open Sans', sans-serif, Arial;

  }

  #zip  {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    vertical-align: middle;
    padding: 10px 27px;
    display: inline-block;
    font-family: 'Open Sans', sans-serif, Arial;
    font-weight: 600;

  }


.tableta  {
display: flex;
align-items: center;
justify-content: center;

}

.tituloso  {
margin-bottom: 25px;
margin-top: 0px;
font-family: 'Open Sans', sans-serif, Arial;

}

table {

font-family: 'Open Sans', sans-serif, Arial;
border-collapse: collapse;
width: 80%;
margin-bottom: 30px;

}

td, th {

text-align: left;
padding: 8px;
}

tr:nth-child(even) {
background-color: rgba(165, 165, 165, 0.5);

}

.a  {
text-align: right;
width: 200px;
font-weight: 600;
border-right: 1px solid #dddddd;
}

</style>
